import React, { useEffect, useState } from 'react';
import './location.css';
import { FaPhoneAlt } from 'react-icons/fa';
import Footer from '../footer/Footer';
import locationimage from '../../assets/IMG_1037.JPG'
import Navbar from '../navbar/Navbar'

const Location = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="location-container">
      <Navbar/>
      <div className="opentimes-bg">
        <img src={locationimage} className="location-image" alt="" />
      </div>
      <div className="opening-times">
        <div className="location-box">
          <h2 className="location-title">Yhteystiedot</h2>
          <div className="location-details">
            <p className="kerma-address"> Tahkolahdentie 5, 73310 Tahkovuori </p>
            <p className="kerma-coordinates"> 63.284501, 28.031731 </p>
            <p className="phone-number">
              <FaPhoneAlt className="phone-icon" /> +358173699090
            </p>
          </div>
          <h1 className="kerma-text">Kerma</h1>
          <h1 className="bistro">Cafe & Bistro</h1>
          <h1 className="times"> Olemme avoinna </h1>
          <div className="schedule-times">
            <ul>
              <li><span>5.1 - 13.2:</span> <span className="fat-time">To - La </span>12:00 - 17:00 </li>
              <li><span>14.2 - 15.3:</span> <span className="fat-time">Ma - La </span>12:00 - 17:00 </li>
              <li><span>16.3 - 12.4 :</span> <span className="fat-time">To - La</span> 12:00 - 17:00 </li>
              <li><span>13.4 - 20.4 </span> <span className="fat-time">To - Su</span> 12:00 - 17:00 </li>
              <li><span>21.4 </span> Alk. suljettu </li>
            </ul>
          </div>
          <p className="closing-text"> Hiihtolomalla palvelemme päivittäin! <span className="skier">⛷️</span> </p>
        </div>
      </div>
      <div className="find-us">
        <h1>Löydät meidät täältä!</h1>
      </div>

      <div className="google-map">
        <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d896.8348356612269!2d28.029680740295063!3d63.28443079927173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468345cb0e50ce01%3A0xa12f277b95215652!2sCafe%20%26%20Bistro%20Kerma!5e0!3m2!1ssv!2sfi!4v1702063123090!5m2!1ssv!2sfi"
        className={windowWidth <= 800 ? 'full-width' : ''}
        width="60%"
          height="400"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <Footer />
    </div>
  );
};

export default Location;