import React from 'react'
import './footer.css'
import kermainverted from '../../assets/kermainverted.png';

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="footer-container">
          <div className="row">
          <img src={kermainverted} className="kerma-logo" alt="kerma-logo" />
            <div className="footer-col">
            
              <h4 className="kerma-openTimes">Aukioloajat</h4>
              <ul>
              <a className="adresslink" href="/location"><li>Tsekkaa tästä meidän aukioloajat</li></a>
              </ul>
            </div>

            <div className="footer-col">
              <h4 className="contact-kerma">Yhteystiedot</h4>
              <div className="footer-links">
                <p>+358 50 4064012</p>
                <p>ravintola@tahkonkerma.fi</p>
              </div>
            </div>
            
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer