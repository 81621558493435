import React, { useRef, useState, useEffect } from 'react';
import './navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import kermaLogo from '../../assets/klogo.png';

const Navbar = () => {
  const navRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const showNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const nav = navRef.current;

      if (window.scrollY > nav.offsetTop) {
        nav.classList.add('sticky');
      } else {
        nav.classList.remove('sticky');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="navbar-container">
      <header
        ref={navRef}
        className={`kerma-header ${navRef.current && window.scrollY > navRef.current.offsetTop ? 'sticky' : ''}`}
      >
        <img src={kermaLogo} alt="Kerma Logo" className="nav-logo" />
        <nav className={`navbar ${isOpen ? 'responsive_nav' : ''}`}>
          <a href="/">Etusivu</a>
          <a href="/location" onClick={closeNavbar}>
            Aukioloajat
          </a>
         {/* <a href="/menu" onClick={closeNavbar}>
            Menu
          </a>*/}
          <a href="/about" onClick={closeNavbar}>
            Meistä
          </a>
          <a href="/gallery" onClick={closeNavbar}>
            Galleria
          </a>
          
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </div>
  );
};

export default Navbar;