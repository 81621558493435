import React, {useEffect} from 'react'
import './menuinfo.css';
import coffeepng from '../../assets/coffee-04.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import img2 from '../../assets/jarvispics/jarvis2.jpg';


export const Menuinfo = () => {
  useEffect(()=> {
    AOS.init({ duration: 1000, once: true });
  }, []);
  
  return (
    <section className="shortmenu" id="shortmenu">
    <div className="menuinfo-container">
      <div className="menuinfo-header">
        <h1 className="our-menus">Tervetuloa! </h1>
      </div>
      <div className="wrapper-menu">
        <img src={img2} className="menu-picture" alt="menupicture" data-aos="fade-up" data-aos-delay="100"/>
        <div className="text-box" data-aos="fade-up" data-aos-delay="200">
          <h2>Tahkon Kerma </h2>
          <img src={coffeepng} className="coffee-png" alt="coffeepng" />
          <p className="kerma-content">Tervetuloa Tahkon Kermaan, jossa tarjoamme herkullisia kotiruokia ja leivonnaisia viihtyisässä ympäristössä. Valikoimaamme kuuluvat muun muassa lasagne, kasvislasagne,  maalaissalaatti broilerilla, gulassikeitto sekä monipuoliset toastit.</p>
          <p className="kerma-content">Jälkiruoaksi tai kahvilla piipahtaessasi voit nauttia tuoreista pullista ja leivonnaisista. </p>
          <p className="kerma-content">Juomavalikoimastamme löydät laadukkaita vaihtoehtoja niin arkeen kuin juhlaan. Tule nauttimaan hyvästä ruoasta, laadukkaista juomista ja rennosta tunnelmasta!</p>
          {/* <a href="/menu" className="menu-cta"> */}
          {/* Näytä Ruokalista */}
          {/* </a> */}
        </div>
      </div>
    </div>
    </section>
  );
};

export default Menuinfo;