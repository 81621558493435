import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Comingsoon from './components/comingsoon/Comingsoon';
import Wipgallery from './components/wipgallery/Wipgallery';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import Location from './components/location/Location';
import { Menuinfo } from './components/menuinfo/Menuinfo'; 
import About from './components/about/About';
import Sponsored from './components/sponsored/Sponsored';
import Smallgallery from './components/smallgallery/Smallgallery'
import Closed from './components/closed/Closed';
import News from './components/news/News';
{/* import Menulist from './components/menulist/Menulist'; */}
{/* import Contactform from './components/contactform/Contactform' */}

const App = () => {
  return (
    <Router>
      
      <Routes>
        <Route path="/" element={<>
          <Navbar />
          <Comingsoon />
          <News />
          <Menuinfo />
          <Sponsored />
          <Smallgallery />
          {/* <Contactform/> */}
          <Footer />
        </>} />
        
        <Route path="/location" element={<Location />} />
        {/* <Route path="/menu" element={<Menulist />} /> */}
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Wipgallery />} />
        <Route path="/closed" element={<Closed />} />
      </Routes>
    </Router>
  );
};

export default App;